



























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {LoginCredentials} from "@/modules/auth/store/types";
import {required, email} from "vuelidate/lib/validators";

Component.registerHooks(['validations'])
@Component({
  components: { SygniContainerTitle, SygniCheckbox, SygniLine, SygniRadio, SygniRoundedButton, SygniInput}
})
export default class Login extends Vue {

  loginCredentials: LoginCredentials = {email: '', password: ''} as LoginCredentials;
  loading: boolean = false;

  validations (){
    return {
      loginCredentials: {
        password: { required },
        email: { required, email }
      }
    }
  }

  async login(){
    this.loading = true;
    if(!this.$v.$error){
      await this.$store.dispatch('auth/login', this.loginCredentials);
      this.loginCredentials.email = '';
      this.loginCredentials.password = '';
      this.$v.$reset();
    }
    this.loading = false;
  }

  goToRemindPassword(){
    this.$router.push({name: 'remind-password'});
  }

}
