var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-form"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "testId": "fe_email",
      "label": 'EMAIL',
      "name": "natural_person[email]",
      "validation": _vm.$v.loginCredentials.email
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.loginCredentials.email.$touch();
      }
    },
    model: {
      value: _vm.loginCredentials.email,
      callback: function callback($$v) {
        _vm.$set(_vm.loginCredentials, "email", $$v);
      },
      expression: "loginCredentials.email"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "testId": "fe_password",
      "label": 'PASSWORD',
      "name": "natural_person[password]",
      "validation": _vm.$v.loginCredentials.password,
      "type": "password"
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.loginCredentials.password.$touch();
      },
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.login();
      }
    },
    model: {
      value: _vm.loginCredentials.password,
      callback: function callback($$v) {
        _vm.$set(_vm.loginCredentials, "password", $$v);
      },
      expression: "loginCredentials.password"
    }
  }), _c('div', {
    staticClass: "forgot-password-text hoverable-a-element",
    on: {
      "click": function click($event) {
        return _vm.goToRemindPassword();
      }
    }
  }, [_vm._v(" Forgot password? ")]), _vm._m(0), _c('sygni-rounded-button', {
    staticClass: "gn-secondary filled",
    attrs: {
      "type": "submit",
      "id": "fe_login",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.login();
      }
    }
  }, [_vm._v(" Sign in ")]), _c('div', {
    staticClass: "register-text"
  }, [_vm._v(" Don't have account? "), _c('router-link', {
    attrs: {
      "to": "register"
    }
  }, [_c('span', {
    staticClass: "register-text--red hoverable-a-element"
  }, [_vm._v(" Register")])])], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "security-text"
  }, [_vm._v(" Your connection is protected by SSL "), _c('img', {
    attrs: {
      "src": require("@/assets/icons/padlock.svg")
    }
  })]);
}]

export { render, staticRenderFns }