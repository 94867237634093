

















import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import {SelectOption} from "@/store/types";

@Component({
  components: {}
})
export default class SygniSelectableTitle extends Vue {

  @Prop({default: []}) options!: Array<SelectOption>;
  @Prop() value!: SelectOption<string>

  get parsedValueToOption(){
    return this.options.find( option => option.value === this.value);
  }

}

