


































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {required, email} from "vuelidate/lib/validators";
import {LiveDemoFormData} from "@/modules/auth/store/types";


Component.registerHooks(['validations'])
@Component({
  components: { SygniContainerTitle, SygniCheckbox, SygniLine, SygniRadio, SygniRoundedButton, SygniInput}
})
export default class Login extends Vue {
  formData: LiveDemoFormData = {disclaimer: false} as LiveDemoFormData;
  isDemoRequestSuccessfullySent: boolean = false;

  mounted(){
  }

  validations (){
    return  {
      formData: {
        email: {required, email},
        company: {required},
        phone: {required},
        name: {required},
        disclaimer: { checked: (value: boolean) => { return value; }}
      },
    }
  }

  async requestDemo(){
    this.$v.$touch();
    if(!this.$v.$error){
      try {
        await this.$store.dispatch('auth/requestDemo', this.formData);
        this.$notify({
          type: 'success',
          title: 'Demo request has been sent'
        });
        this.formData.company = '';
        this.formData.name = '';
        this.formData.email = '';
        this.formData.phone = '';
        this.$v.$reset();
        this.isDemoRequestSuccessfullySent = true;
      } catch(e) {
        this.$notify({
          type: 'error',
          title: 'An error has occurred'
        });
      }
    }
  }

  async showRegulations(){
    let routeData = this.$router.resolve({name: 'regulations'});
    window.open(routeData.href, '_blank');
  }

  goToHomePage() {
    this.$store.dispatch('genprox/goToLandingPage');
  }
}
