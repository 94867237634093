var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "register"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "header"
  }, _vm._l(_vm.options, function (option) {
    return _c('div', {
      key: option.value,
      class: "header__text " + (option === _vm.parsedValueToOption ? "red" : "grey"),
      on: {
        "click": function click($event) {
          return _vm.$emit('input', option.value);
        }
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  }), 0)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }